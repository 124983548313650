<template>
    <div class="jobfair">
        <left-tab :tabs="tabs" @change="onTabChange" :active="tabsActive">
            <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                <empty-page v-if="!list||list.length===0" msg="暂无相关信息"/>
                <div class="list" style="padding: 0 0.1rem">
                    <jobfair-list-cell v-for="(item, index) in list" :key="index" :item="item"/>
                </div>
            </refresh-list>

        </left-tab>
    </div>
</template>

<script>
// jobfair
import LeftTab from "@/components/left-tab";
import JobfairListCell from "@/components/jobfair-list-cell";
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
export default {
    name: "jobfair",
    components: {EmptyPage, RefreshList, JobfairListCell, LeftTab},
    data() {
        return {
            tabsActive: 0,
            tabs: [{
                title: "网络会",
                value: 2
            }, {
                title: "线下会",
                value: 1,
            }],
            list: [],
            pageIndex: 1,
            pageSize: 20,
        }
    },
    mounted() {
        this.loadPageList();
    },
    methods: {
        onTabChange(index) {
            console.log(index)
            this.tabsActive = index;
            this.pageIndex = 1;
            this.list = [];
            this.loadPageList();
        },
        async loadPageList() {
            // path /BaseJobFair/GetJobFairList
            // 是否是加载更多
            const params = {
                jobFairType: this.tabs[this.tabsActive].value,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                sort: 'StartTime',
                sortType: 'desc'
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/GetJobFairList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];

                newList = newList.map(item => {
                    item.timeText = $app.dateFormatChinese(item.BaseCreateTime);
                    let current = new Date().getTime();
                    if (item.StartTime && item.EndTime) {

                        let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                        let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                        if (current < start) {
                            item.status = 0;
                        } else if (current >= start && current <= end) {
                            item.status = 1;
                        } else if (current > end) {
                            item.status = 2;
                        }
                        item.startTime = $app.dateFormatChinese(item.StartTime);
                        item.endTime = $app.dateFormatChinese(item.EndTime);
                    } else if (item.StartTime && !item.EndTime) {
                        let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                        if (current < start) {
                            item.status = 0;
                        } else if (current >= start) {
                            item.status = 1;
                        }
                        item.startTime = $app.dateFormatChinese(item.StartTime);
                    } else if (!item.StartTime && item.EndTime) {
                        let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                        if (current > end) {
                            item.status = 2;
                        } else if (current <= end) {
                            item.status = 1;
                        }
                        item.endTime = $app.dateFormatChinese(item.EndTime);
                    } else if (!item.StartTime && !item.EndTime) {
                        item.status = 1;
                    }

                    return item;
                });

                if (this.pageIndex === 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }

        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.jobfair {
}
</style>
