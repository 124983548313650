<template>
    <div class="component flex-row flex-j-between flex-a-center tap box-sizing">
        <lazy-img v-if="!hideimg" class="image" :src="item.TitleImgUrl" @click="onDetailClick"/>
        <div class="flex-col flex-j-between flex-a-start" style="flex: 1;margin-left: 0.4rem;height: 100%">
            <div class="row flex-row flex-j-start flex-a-center line-num-2">
                <span v-if="item.status==0" class="status status-0" @click="onDetailClick">未开始</span>
                <span v-if="item.status==1" class="status status-1" @click="onDetailClick">进行中</span>
                <span v-if="item.status==2" class="status status-2" @click="onDetailClick">已结束</span>
                <span class="title line-num-2" @click="onDetailClick">{{item.TitleName}}</span>
            </div>
            <div class="row flex-row flex-j-between flex-a-end">
                <div class="flex-1" style="">
                    <div v-if="item.startTime" class="row" @click="onDetailClick">
                        开始时间：{{item.startTime}}
                    </div>
                    <div v-if="item.endTime" class="row" @click="onDetailClick">
                        结束时间：{{item.endTime}}
                    </div>
                </div>
                
            </div>
            <div class="flex-row flex-j-around flex-a-end">
                <div v-if="userInfo.MemberType > 1 && item.status == 0" class="btn tap" @click="onBaoming">立即报名</div>
                <div v-if="item.status == 1 && item.JobFairType==2" class="btn tap" @click="onJinru">进入会场</div>
                <div v-else>
                    <span v-if="item.status==0">未开始</span>
<!--                    <span v-if="item.status==1">进行中</span>-->
                    <span v-if="item.status==2">已结束</span>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
// jobfair-list-cell
import LazyImg from "@/components/lazy-img";
export default {
    name: "jobfair-list-cell",
    components: {LazyImg},
    props: {
        // 状态
        // 0 未开始
        // 1 进行中
        // 2 已结束
        item: {
            type: Object
        },
        hideimg: {
            type: Boolean
        },
        hideborder: {
            type: Boolean
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    data() {
        return {
            // userInfo: {},
        }
    },
    mounted() {
        // this.userInfo = $app.getUserInfo();
    },
    methods: {
        onDetailClick() {
            this.$router.push(`/jobfair-detail?id=${this.item.Id}`)
        },
        async onBaoming() {
            // /BaseJobFair/ApplyJobFair
            const params = {
                jobFairId: this.item.Id,
                CompanyId: this.userInfo.Id
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/ApplyJobFair`,
                params: params,
                loading: '报名中...'
            });

            if (res.ok) {
                $app.showToast({
                    title: res.message,
                })
            }
        },
        onJinru() {
            // console.log("进入会场")
            this.$router.push(`/jobfair-detail-list?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.jobfair-list-cell {
}

.component {
    padding: .40rem;
    /* box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.2); */
    border-radius: .12rem;
    background-color: #fff;
    //margin-bottom: .20rem;
    font-size: .28rem;
    width: 100%;
    text-align: left;
    height: 4.4rem;
    //border-bottom: 1px solid gainsboro;
    margin-bottom: 0.2rem;
    
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}
.component .image {
    width: 8.0rem;
    height: 3.60rem;
    border-radius: .12rem;
    overflow: hidden;
}

.component .title {
    font-size: .32rem;
    padding: .10rem 0;
    height: 1.0rem;
    //max-width: 10.40rem;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    
}
.component .des {
    font-size: .28rem;
    color: #999999;
}

.row,
.btn-group {
    width: 100%;
    padding: .06rem 0;
    color: #666666;
}
.title {
    font-size: .32rem;
}
.status {
    font-size: .24rem;
    color: #fff;
    padding: .06rem .20rem;
    background-color: orange;
    border-radius: .06rem;
    margin-right: .20rem;
}
.status-0 {
    background-color: rgb(78, 171, 232);
}
.status-1 {
    background-color: #f1676d;
}
.status-2 {
    background-color: gainsboro;
}
.btn {
    padding: .10rem .20rem;
    color: #fff;
    border-radius: .06rem;
    margin-right: 0.4rem;
    background-image: linear-gradient(to right, #1989fa, rgba(78, 171, 232, 0.8));
    cursor: pointer;
}
</style>
